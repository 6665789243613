<template>
  <div class="tab-container">
  <el-tabs v-model="activeName"  >
    <el-tab-pane name="gl_itog" label="Краткие итоги">
      <div style="padding-left: 2%" v-html="glitog()"/> 
    </el-tab-pane>
    <el-tab-pane name="table_itog" label="Таблица">  
    <div class="txt">
      <el-col :span="23"><span class="buttxt">{{$store.getters['curElect'].name}}. </span> Результаты голосования.</el-col>
      <el-popover content="Сохранить в файл .csv" trigger="hover" >
      <el-button icon="el-icon-document" slot="reference" @click="csvSave" size="mini"></el-button>
      </el-popover>
    </div>
   <el-table ref="tabitog"
    :data="el_result"
    border
    :header-row-style="headerRowStyle"
    :header-cell-style="headerCellStyle"
    :cell-style="cellStyle"
    @row-click="selrow"
    @row-contextmenu="contextmenu"
    :default-sort = "{prop: 'username'}"
    show-summary sum-text="Итог" :summary-method="itog"
    height="800"
    style="width: 100%">
    <el-table-column
      type="index"
      width="50" fixed>
    </el-table-column>
    <el-table-column
      label="ФИО"
      prop="username" sortable width="180" fixed>
      <template slot-scope="sc">
        <span class="txt" >{{ sc.row.username }}</span>
      </template>
    </el-table-column>
    <el-table-column
      :label="`Ответов (из ${el_quests.length})`"
      :sort-method="sort"
      prop="klot" sortable width="80" fixed>
      <template slot-scope="sc">
        <span class="tab" :style="sc.row.klot==el_quests.length?'color:navy':'color:red'">{{ sc.row.klot }}</span>
      </template>
    </el-table-column>
    <el-table-column v-for="(col, ind) in el_quests" 
      :label="col.label" :key="col.key" min-width="130" :prop="'c'+ind"
      :sortable="col.sortable" >
      <template slot-scope="scope">
        <!-- <span class="tab" :style="scope.row['c'+ind]=='Против'?'background-color:#f59085':scope.row['c'+ind]=='--'?'background-color:lightgrey':''"> -->
        <span class="tab" >
          {{ scope.row['c'+ind] }}</span>
      </template>
    </el-table-column>
   </el-table> 
    </el-tab-pane>
  </el-tabs>
 </div>
</template>

<script>
//import axios from 'axios'
import { saveAs } from "file-saver";

export default {
  data() {
    return {
      activeName: "table_itog",
      el_result: [],
      el_quests: [],
      gl_itog: [],
    }
  },
  mounted: async function () { 
    //let result = await axios.post(`${hostus}/mysqlus/select`, { username: '*' })
    //let elect = JSON.parse(this.$store.getters['curElect'].content)
    let ind = 0
    let elms = this.$store.getters['curElectQuestions']
    elms.forEach(element => {
      //if (element.choices.length > 0)
        this.el_quests.push({label: element.name, sortable: true, class: "txt", key: ind++, vars: element.choices})
    });

    let cur = this.$store.getters['curResult']; //console.log('cur=',cur)
    cur.forEach(item => {
      const index = item.username.lastIndexOf('.')
      let row = { username: item.username.slice(0, index+1) }

      this.el_quests.forEach((quest) => {row['c'+quest.key] = '--'}) // init полей таблицы прочерком

      let klot = 0
      for (const key in item.el_res) { 
        if (Object.hasOwnProperty.call(item.el_res, key)) {
          let ind = this.el_quests.findIndex(it => it.label.trim()==key.trim())
          if (ind >= 0) {row['c'+ind] = item.el_res[key]; klot++}
        }
      }
      row.klot = `${klot}` //+ (klot == this.el_quests.length ? ``:` (из ${this.el_quests.length})`)
      if (this.$store.getters['curElect'].secret) row.username = 'Участник ' 
      this.el_result.push(row)
    })

    if (this.$store.getters['debug']) console.log('this.el_result=', this.el_result,this.el_quests)
  },
  computed: {
  },
  methods: {
    sort (a, b) {  
      let an=Number(a.klot);
      let bn=Number(b.klot); 
      return an - bn
    },
    headerRowStyle () {
      return {color: '#611f00', margin: '4px'}
    },
    headerCellStyle () {
      return {background: '#f3efef'}
    },
    cellStyle ({row, column}) {
      if (row[column.property] == '--') return {background: '#d9eaf5'}
      if (row[column.property] == 'Против') return {background: '#f39e9e'}
      if (row[column.property] == 'Воздержался') return {background: 'lightyellow'}
    },
    itog (param) {
      const { columns, data } = param;
      
      const sums = [];
      columns.forEach((col, ind) => { // цикл по столбцам
        if (ind > 2) {
          const vars = this.el_quests.find((item) => 'c' + item.key == col.property)
          // 
          sums[ind] = ''
          let glkl = 0
          vars.vars.forEach((vr) => { // цикл по вариантам ответов
            let kl = data.reduce((klv, it) => {
              if (it[col.property] == vr.value) { klv++; return klv}
              else return klv
            }, 0)
            glkl += kl
            sums[ind] = sums[ind] + ` ${vr.value}: ${kl};`  
          })
          //sums[ind] = data.length
          if (data.length - glkl > 0) sums[ind] = sums[ind] + ` Не ответил: ${data.length - glkl};`
          this.gl_itog[ind-3] = {label: col.label, itog: sums[ind]}
        }
      })
      //console.log('sums=',sums,this.gl_itog)
      return sums
    },
    glitog () {
      const kl = this.$store.getters['kl_elector']
      const klres = this.el_result.length
      let itog = `<strong>${this.$store.getters['curElect'].name}. Общие итоги</strong><br><br>
        Проголосовали ${klres} из ${kl} (${(100*klres/kl).toFixed(2)}%)`
      this.gl_itog.forEach(element => {
        itog += `<p style="color:navy">${element.label} : </p><ul><li type="square"><tr>`
        let arr = element.itog.split(';')
        let max = arr.reduce((max, item) => {
          if (Number(item.split(':')[1]) > max) max = Number(item.split(':')[1]); return max}, 0) 
        for (const a of arr) {
          if (a) {
            let k = Number(a.split(':')[1])
            let stl = k == max ? `style="background-color: #fad6d2"` : `` 
            itog += `<td><span ${stl}>${a} (${(100*k/klres).toFixed(2)}%)</span></td>
              <td><span style="color:white"> *** </span></td>`
          }
        }
        itog += '</tr></li></ul>'
      })
      return itog
      //this.$alert(itog, title, { confirmButtonText: 'Закрыть', showClose: false, dangerouslyUseHTMLString: true})
    },
    csvSave () {
      let fname = this.$store.getters['curElect'].name + '.Результаты голосования'
      this.$prompt('(имя можно изменить)', 'Сохранить в файле...', {inputValue: fname})
      .then((x)=>{         
        if (x.action == 'confirm') {
          let rescsv = '', i = 0
          const data = this.$refs['tabitog'].tableData // данные, как на экране
          const columns = this.$refs['tabitog'].columns
          columns.forEach((col) => { rescsv += (col.label || ' ') + '\t'})
          rescsv += '\n'
          data.forEach((row) => {
            rescsv += ++i + '\t' //+ row.username + '\t' + row.klot + '\n'
            columns.forEach((col) => { if (col.property) rescsv += (row[col.property] || '?') + '\t'})
            rescsv += '\n'
          })

          fname = x.value
          const blb = new Blob([rescsv], { type: 'application/text' })
          saveAs(blb, fname + '.csv')
        }
      }).catch(()=>{})
    },

    contextmenu (row,col) {
      if (this.$store.getters['debug']) console.log('col=', col,"$refs['tabitog']=",this.$refs['tabitog'])
    },
    selrow (row) {
      if (this.$store.getters['debug']) console.log('row=', row)
    },
  },
}
</script>

<style  scoped>
.tab-container {
    position: relative;
    width: 100%;
    max-width: 100%;
    /* padding: 2px 0px 2px; */
    /* margin: 0 auto; */
  background-color:rgb(245, 244, 244);
  overflow: hidden;
}
.butt{
  margin-left:60px;
  margin-top:0px;
  margin-bottom:20px;
}
.buttxt {
  font-size: 1rem;
  color:darkslategrey;
}
.txt {
  font-size: .9rem;
  margin: 8px;
  color: navy;
}
.tab {
  font-size: .8rem;
  margin: 8px;
  color:rgb(88, 1, 88);
}
.test {
  color:#fad6d2;
  background-color: #e2eaef;
}
</style>